import VueRouter from 'vue-router'
import { setPageTitle } from '@/helpers/pageTitle'

// Layout
const BaseLayout = () => import('@/views/_layouts/BaseLayout')

// Common Pages
const Comingsoon = () => import('@/views/_general/Comingsoon')
const Error404 = () => import('@/views/_general/404')
const ForgotPassword = () => import('@/views/_general/ForgotPassword')
const VerifyEmail = () => import('@/views/_general/VerifyEmail')
const Login = () => import('@/views/_general/Login')
const Register = () => import('@/views/_general/Register')
const ResetPassword = () => import('@/views/_general/ResetPassword')

// Pages
const AuthProfile = () => import('@/views/_auth/AuthProfile')

const Home = () => import('@/views/_general/Home')

const UserList = () => import('@/views/Users/UserList')
const UserProfile = () => import('@/views/Users/UserProfile')

const SupportiveSingle = () => import('@/views/Supports/SupportiveSingle')

const FAQList = () => import('@/views/Supports/FAQ')

const AuditTrailList = () => import('@/views/AuditTrails/AuditTrailList')
const AuditTrailProfile = () => import('@/views/AuditTrails/AuditTrailProfile')

const AnnouncementList = () => import('@/views/Announcements/AnnouncementList')

const AdvertisementList = () => import('@/views/Advertisements/AdvertisementList')

const SettingPage = () => import('@/views/SystemVariables/SettingPage')
const SecuritySettingPage = () => import('@/views/SystemVariables/SecuritySettingPage')

const BroadcastList = () => import('@/views/Broadcasts/BroadcastList')

// Routes
// ** for more information, kindy refer to https://github.com/websanova/vue-auth/blob/master/docs/Privileges.md
// ** auth: true <- can be access only if auth.check = true
// ** auth: false <- cannot be access is auth.check = true
// ** auth: null, title: '' <- no auth rule
// ** auth: [1,2] <- can be access is auth.user.role matched

const baseLayoutRoutes = [
	{ path: '/comingsoon', name: 'admin.home2', component: Comingsoon, meta: { auth: true, role: [1, 3], title: 'Coming Soon' } },
	{ path: '/home', name: 'home', component: Home, meta: { auth: true, role: [1, 3], title: 'Home' } },

	// user
	{ path: '/user/list', name: 'user.list', component: UserList, meta: { auth: true, role: [1, 3], title: 'User List' } },
	{ path: '/my/profile', name: 'my.profile', component: AuthProfile, meta: { auth: true, role: [1, 3], title: 'Profile' } },
	{ path: '/user/profile/:id', name: 'user.profile', component: UserProfile, meta: { auth: true, role: [1, 3], title: 'User Profile' } },
	{ path: '/user/profile', name: 'user.my-profile', component: UserProfile, meta: { auth: true, role: [1, 3], title: 'My Profile' } },

	// support
	{ path: '/support/terms-of-use', name: 'support.terms-of-use', component: SupportiveSingle, meta: { auth: true, role: [1], title: 'T&C', params: { type: 'terms-of-use' } } },
	{ path: '/support/privacy-policy', name: 'support.privacy-policy', component: SupportiveSingle, meta: { auth: true, role: [1], title: 'T&C', params: { type: 'privacy-policy' } } },
	{ path: '/support/faq', name: 'support.faq', component: FAQList, meta: { auth: true, role: [1], title: 'FAQ', params: { type: 'faq' } } },
	{ path: '/support/withdrawal-tnc', name: 'support.withdrawal-tnc', component: SupportiveSingle, meta: { auth: true, role: [1], title: 'Arbitrage Policy Update', params: { type: 'withdrawal-tnc' } } },


	//audit trails
	{ path: '/audit-trail/list', name: 'audit-trail.list', component: AuditTrailList, meta: { auth: true, role: [1], title: 'Audit Trail List' } },
	{ path: '/audit-trail/profile/:id', name: 'audit-trail.profile', component: AuditTrailProfile, meta: { auth: true, role: [1], title: 'Audit Trail Profile' } },

	{ path: '/announcement/list', name: 'announcement.list', component: AnnouncementList, meta: { auth: true, role: [1], title: 'Announcement List' } },

	{ path: '/advertisement/list', name: 'advertisement.list', component: AdvertisementList, meta: { auth: true, role: [1], title: 'Advertisement List' } },

	{ path: '/setting', name: 'setting.page', component: SettingPage, meta: { auth: true, title: 'Settings', role: [1], page_category: "setting" } },

	{ path: '/security-setting', name: 'security-setting.page', component: SecuritySettingPage, meta: { auth: true, title: 'Security Settings', role: [1], page_category: "security-setting" } },

	// broadcast
	{ path: '/broadcast/list', name: 'broadcast.list', component: BroadcastList, meta: { auth: true, role: [1], title: 'Broadcast list' } },
]

const routes = [

	{ path: '/', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/login', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/register/:code', name: 'register', component: Register, meta: { auth: null, title: 'Register' } },
	{ path: '/password/reset/:token', name: 'reset.password', component: ResetPassword, meta: { auth: null, title: 'Reset Password' } },
	{ path: '/forgot-password', name: 'forgot.password', component: ForgotPassword, meta: { auth: null, title: 'Forgot Password' } },
	{ path: '/email-verify/:token', name: 'verify.email', component: VerifyEmail, meta: { auth: null, title: 'Verify Email' } },

	// base_layout
	{ path: '/home', name: 'base_layout', component: BaseLayout, children: baseLayoutRoutes },

	{ path: '*', name: '404', component: Error404, meta: { auth: null, title: '404' } },
]

const router = new VueRouter({
	history: true,
	mode: 'history',
	routes,
})

router.beforeEach((to, from, next) => {
	setPageTitle(to.meta.title)
	next()
});

export default router